import React from "react"
import styled from "styled-components"

const StyledList = styled.ol`
  > li {
    margin-bottom: 20px;

    > ol {
      margin-top: 10px;
      > li {
        margin-bottom: 5px;
      }
      &[type="1"] {
        li {
          counter-increment: item;
          :first-child {
            counter-reset: item;
          }
          :before {
            content: counters(item, ". ") ". ";
            margin-left: -3px;
            margin-right: 1px;
          }
        }
      }
      .reset-list {
        li {
          :before {
            content: "";
          }
        }
      }
    }
  }
`

const PrivacyPolicyPage = () => (
  <div className="container">
    <h1 className="mt8 header4">
      Informacja o Przetwarzaniu Danych Osobowych i Polityka Prywatności
    </h1>
    <p className="body-small">
      Niniejszy dokument zawiera informację odnoszącą się do przetwarzania przez
      Administratora danych osobowych, o której mowa w art. 13 RODO
      (ROZPORZĄDZENIE PARLAMENTU EUROPEJSKIEGO I RADY (UE) 2016/679 z dnia 27
      kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
      przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
      danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
      danych)) i stanowi formę udostępnienia kontrahentom My Wash spółki z
      ograniczoną odpowiedzialnością spółki komandytowej z siedzibą w
      Białymstoku, ul. Pińska 2, 15 – 379 Białystok, KRS nr: 0000733308, NIP:
      9662120995, REGON: 380305239 takiej informacji za pośrednictwem Serwisu, a
      nadto stanowi Politykę Prywatności i określa zasady przechowywania i
      dostępu do informacji na urządzeniach Użytkownika za pomocą plików
      Cookies, służących realizacji usług świadczonych drogą elektroniczną,
      żądanych przez My Wash spółkę z ograniczoną odpowiedzialnością spółkę
      komandytowa z siedzibą w Białymstoku, ul. Pińska 2, 15 – 379 Białystok,
      KRS nr: 0000733308, NIP: 9662120995, REGON: 380305239 (tj.: Administratora
      w rozumieniu ust 1 lit a) poniżej).
    </p>
    <StyledList type="1" className="body-small">
      <li>
        <strong>
          Ilekroć w niniejszym dokumencie używa się pojęć przywołanych w lit a)
          – h) poniżej, mają one następujące znaczenie (Definicje):
        </strong>
        <ol type="a">
          <li>
            Administrator, bądź Administrator danych osobowych (w tym podanych
            przez Państwa za pośrednictwem Serwisu) – oznacza: My Wash spółka z
            ograniczoną odpowiedzialnością spółka komandytowa z siedzibą w
            Białymstoku, ul. Pińska 2, 15 – 379 Białystok, województwo
            podlaskie, KRS nr: 0000733308, NIP: 9662120995, REGON: 380305239,
            tel. 691120686 (dalej również „My Wash”).
          </li>
          <li>
            Cookies - oznacza dane informatyczne, w szczególności niewielkie
            pliki tekstowe, zapisywane i przechowywane na urządzeniach, za
            pośrednictwem których Użytkownik korzysta ze stron internetowych
            Serwisu.
          </li>
          <li>
            Cookies Administratora - oznacza Cookies zamieszczane przez
            Administratora, związane ze świadczeniem usług drogą elektroniczną
            przez Administratora za pośrednictwem Serwisu.
          </li>
          <li>
            Cookies Zewnętrzne - oznacza Cookies zamieszczane przez partnerów
            Administratora, za pośrednictwem strony internetowej Serwisu.
          </li>
          <li>
            Serwis - oznacza: stronę internetową, pod którą Administrator
            prowadzi serwis internetowy, działający w domenie https://mywash.pl/
            i jej subdomenach.
          </li>
          <li>
            Dane osobowe: wszelkie informacje o zidentyfikowanej lub możliwej do
            zidentyfikowania osobie fizycznej ("osobie, której dane dotyczą");
            możliwa do zidentyfikowania osoba fizyczna to osoba, którą można
            bezpośrednio lub pośrednio zidentyfikować, w szczególności na
            podstawie identyfikatora takiego jak imię i nazwisko, numer
            identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub
            jeden bądź kilka szczególnych czynników określających fizyczną,
            fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub
            społeczną tożsamość osoby fizycznej; dla uchylenia wątpliwości
            Cookies stanowią dane osobowe i podlegają u Administratora
            przetwarzaniu w sposób przypisany dla danych osobowych;
          </li>
          <li>
            Urządzenie - oznacza elektroniczne urządzenie, za pośrednictwem
            którego Użytkownik uzyskuje dostęp do Serwisu.
          </li>
          <li>Użytkownik - oznacza podmiot korzystający z Serwisu.</li>
        </ol>
      </li>
      <li>
        <strong>
          Administrator, przetwarzając Państwa dane osobowe stosuje środki
          organizacyjne i techniczne zgodne z przepisami polskiego prawa oraz
          zgodnie z rozporządzeniami unijnymi, oraz podejmuje wszelkie niezbędne
          działania w celu zabezpieczenia Państwa danych przed jakimikolwiek
          naruszeniami, w tym przed ich przypadkowym lub niezgodnym z prawem
          zniszczeniem, utratą, modyfikacją, nieuprawnionym ujawnieniem lub
          przed nieuprawnionym dostępem do danych osobowych.
        </strong>
      </li>
      <li>
        <strong>Przetwarzanie danych osobowych informacje ogólne</strong>
        <ol start="3" type="1">
          <li value="3">
            <strong>
              Cel przetwarzania Państwa danych osobowych oraz kategorie danych:{" "}
            </strong>
            Państwa dane osobowe są przetwarzane przez My Wash, jako
            Administratora, w celu zawarcia i wykonania umowy o świadczenie na
            Państwa rzecz usług, bądź sprzedaży towarów, ustalenia, obrony i
            dochodzenia ewentualnych roszczeń My Wash względem Państwa, jak
            również marketingu bezpośredniego, jeśli wyraziliście Państwo zgodę
            na przetwarzanie danych osobowych w tym zakresie. Przedmiotem
            przetwarzania są Państwa dane osobowe w kategorii danych
            kontaktowych. Szczegółowe oznaczenie celu przetwarzania plików
            Cookies zostało opisane w punkcie 4.2.2 niniejszego dokumentu.
          </li>
          <li value="3">
            <strong>
              Podstawa przetwarzania danych osobowych wraz z ew. opisem prawnie
              uzasadnionych interesów realizowanych przez administratora:{" "}
            </strong>
            Państwa dane osobowe są przetwarzane przez My Wash na podstawie art.
            6 ust. 1 lit. b RODO, tzn.: przetwarzanie jest niezbędne do
            wykonania umowy, której stroną jest osoba, której dane dotyczą, lub
            do podjęcia działań na żądanie osoby, której dane dotyczą, przed
            zawarciem umowy. Innym ważnym aspektem jest przepis art. 6 ust. 1
            lit. c RODO, tzn.: przetwarzanie jest niezbędne do wypełnienia
            obowiązku prawnego ciążącego na administratorze, są to np. przepisy
            prawa, które wymagają od My Wash przetwarzania danych dla celów
            podatkowych i rachunkowych. Kolejnym ważnym aspektem jest przepis
            art. 6 ust. 1 lit. f RODO, tzn.: przetwarzanie jest niezbędne do
            celów wynikających z prawnie uzasadnionych interesów realizowanych
            przez administratora lub przez osobę trzecią, jest to np.:
            egzekwowanie należności, bądź dochodzenie roszczeń czy to
            Administratora, czy to jego klientów, marketing naszych produktów
            lub usług.
          </li>
          <li value="3">
            <strong>Informacja o odbiorcach danych osobowych: </strong>
            Państwa dane osobowe mogą być przekazywane odbiorcom, aby realizować
            cele wymienione w punkcie 3.1. w zakresie, w jakim są dane te są
            niezbędne do wykonania zadań zleconych przez administratora danych,
            jeżeli wymaga tego przepis prawa lub jeżeli administrator posiada
            inną podstawę prawną. Państwa dane osobowe mogą być przekazywane:
            pracownikom i współpracownikom My Wash, którzy muszą mieć dostęp do
            danych, aby wykonywać swoje obowiązki, jeśli będą osobami
            upoważnionymi przez Administratora do wykorzystania takich danych w
            ramach wykonywania swoich obowiązków służbowych; dane te mogą być
            również przekazywane uprawnionym organom, w tym Sądom powszechnym
            oraz Sądowi Najwyższemu, Wojewódzkim Sądom Administracyjnym oraz
            Naczelnemu Sądowi Administracyjnemu, organom administracji
            publicznej bądź urzędom w sytuacji, w której wymaga tego od My Wash
            przepis prawa, jak również – w razie potrzeby – mogą być
            udostępniane dostawcom na rzecz My Wash usług prawnych, księgowych,
            kurierskich lub pocztowych; firmom transportowym lub spedytorskim,
            jeśli będzie to celowe dla wykonania przez My Wash na Państwa rzecz
            usług, dane w takim wypadku mogą być również przekazywane innym
            osobom w ramach Państwa organizacji.
            <p className="body-small">
              W tym Państwa dane osobowe przekazywane są podmiotowi
              zarządzającemu i reprezentującemu My Wash, tj. jego
              komplementariuszowi, w tym członkom zarządu oraz pracownikom
              komplementariusza, jak również podmiotom świadczącym na rzecz
              komplementariusza na podstawie innej niż stosunek pracy.
              Komplementariuszem My Wash jest spółka My Wash spółka z
              ograniczoną odpowiedzialnością z siedzibą w Białymstoku, ul.
              Pińska 2, 15 – 379, KRS: 0000732467, NIP: 9662120908, REGON:
              380263937.
            </p>
          </li>
          <li value="3">
            <strong>
              Informacja o zamiarze przekazania danych osobowych do państwa
              trzeciego:
            </strong>
            Co do zasady Administrator nie przekazuje danych osobowych klientów
            do odbiorców znajdujących się poza Europejskim Obszarem
            Gospodarczym. Przekazanie Danych osobowych klienta poza obszar EOG
            może jednak nastąpić, a wynikać to może wyłącznie z realizacji
            zleceń pochodzących od Państwa. W powyższych przypadkach, Państwa
            dane osobowe są chronione za pomocą standardowych klauzul ochrony
            danych, które zgodnie z art. 46 ust. 2 RODO stanowią odpowiednie
            zabezpieczenia w przypadku transferu danych do krajów spoza EOG.
          </li>
          <li value="3">
            <strong>
              Informacja o okresie przechowywania danych osobowych:
            </strong>
            Państwa dane osobowe będą przechowywane w związku ze świadczeniem
            przez My Wash na Państwa rzecz usług przez okres realizacji tych
            usług, nie krócej niż do czasu ich przedawnienia w celu umożliwienia
            My Wash dochodzenia od Państwa ewentualnych roszczeń, jak również
            przez okres, w odniesieniu do którego z przepisów prawa, szczególnie
            prawa podatkowego i rachunkowego wynika obowiązek My Wash
            dokonywania czynności związanych z przetwarzaniem Państwa danych,
            choćby w zakresie archiwizacji dokumentów dla celów księgowych,
            rachunkowych, czy podatkowych.
          </li>
          <li value="3">
            <strong>
              Informacja o prawie dostępu do danych osobowych, ich sprostowania,
              usunięcia danych osobowych lub ograniczenia przetwarzania, o
              prawie do wniesienia sprzeciwu oraz o prawie do przenoszenia
              danych:{" "}
            </strong>
            My Wash umożliwia dostęp do własnych danych osobowych (informację o
            danych, kopię danych); sprostowanie danych; usunięcie danych
            (przetwarzanych bezpodstawnie); ograniczenie przetwarzania
            (wstrzymanie operacji na danych lub nieusuwanie danych – stosownie
            do złożonego wniosku); przeniesienie danych do innego administratora
            danych. W zakresie, w jakim podstawą przetwarzania Państwa danych
            osobowych jest art. 6 ust. 1 lit. f RODO, przysługuje Państwu prawo
            wniesienia sprzeciwu wobec przetwarzania Państwa danych osobowych w
            szczególności na potrzeby marketingu produktów lub usług My Wash.
          </li>
          <li value="3">
            <strong>
              Informacja o zautomatyzowanym podejmowaniu decyzji, w tym
              profilowaniu, o którym mowa w art. 22 ust. 1 i 4 RODO oraz istotne
              informacje o zasadach ich podejmowania, a także o znaczeniu i
              przewidywanych konsekwencjach takiego przetwarzania dla osoby,
              której dane dotyczą:{" "}
            </strong>
            decyzje, które dotyczą Państwa, nie są podejmowane w sposób
            zautomatyzowany, ani nie jest dokonywane profilowanie, o którym mowa
            w art. 22 ust. 1 i 4 RODO;
          </li>
          <li value="3">
            <strong>
              Informacja o dobrowolności podania danych osobowych:{" "}
            </strong>
            Podanie przez Państwa danych osobowych jest dobrowolne w przypadku
            zawarcia umowy świadczenia przez My Wash usług bądź sprzedaży
            towarów, niemniej jednak bez podania takich danych niemożliwym może
            okazać się świadczenie przez My Wash usług (z uwagi na ich
            charakter) oraz niemożliwym może okazać się wystawienie i
            przekazanie Państwu dokumentu potwierdzającego wykonanie usługi,
            wydanie towaru, bądź zapłaty za usługę bądź towar lub dokumentu
            stanowiącego fakturę. W przypadku marketingu usług świadczonych
            przez My Wash podanie przez Państwa danych osobowych jest
            dobrowolne.
          </li>
          <li value="3">
            <strong>
              Informacja o prawie do wniesienia skargi do organu nadzorczego:{" "}
            </strong>
            Mają Państwo prawo do wniesienia skargi do organu nadzorczego,
            zajmującego się ochroną danych osobowych – Prezesa Urzędu Ochrony
            Danych Osobowych.
          </li>
        </ol>
      </li>
      <li>
        <strong>
          Udostępnienie danych, w tym danych osobowych Administratorowi za
          pośrednictwem Serwisu.{" "}
        </strong>
        <ol type="1" start="4">
          <li value="4">
            <strong>Jeśli zamierzacie Państwo skorzystać z formularza, </strong>
            udostępnionego w Serwisie w celu uzyskania informacji o możliwości
            współpracy z Administratorem, zostaniecie Państwo poproszeni o
            podanie danych osobowych, w zakresie Państwa imienia, nazwiska,
            miejscowości w której posiadacie Państwo siedzibę bądź miejsce
            zamieszkania oraz adresu poczty e-mail, numer telefonu, jak również
            o podanie w polu „treść wiadomości” treści Państwa zapytania.
          </li>
          <li value="4">
            <strong>
              Serwis korzysta z tzw. plików Cookies (ciasteczka),{" "}
            </strong>
            a poniżej przedstawia się szczegółowe informacje w tym zakresie.
            <ol type="1" start="4">
              <li value="4">
                <strong>Rodzaje wykorzystywanych Cookies</strong>
                <ol type="1" start="4">
                  <li value="4">
                    Stosowane przez Administratora Cookies są bezpieczne dla
                    Urządzenia Użytkownika. W szczególności tą drogą nie jest
                    możliwe przedostanie się do Urządzeń Użytkowników wirusów
                    lub innego niechcianego oprogramowania lub oprogramowania
                    złośliwego. Pliki te pozwalają zidentyfikować oprogramowanie
                    wykorzystywane przez Użytkownika i dostosować Serwis
                    indywidualnie każdemu Użytkownikowi. Cookies zazwyczaj
                    zawierają nazwę domeny z której pochodzą, czas
                    przechowywania ich na Urządzeniu oraz przypisaną wartość.
                  </li>
                  <li value="4">
                    Administrator wykorzystuje dwa typy plików cookies: a.
                    Cookies sesyjne: są przechowywane na Urządzeniu Użytkownika
                    i pozostają tam do momentu zakończenia sesji danej
                    przeglądarki. Zapisane informacje są wówczas trwale usuwane
                    z pamięci Urządzenia. Mechanizm cookies sesyjnych nie
                    pozwala na pobieranie jakichkolwiek danych osobowych ani
                    żadnych informacji poufnych z Urządzenia Użytkownika.
                    <ol type="a" className="reset-list">
                      <li>
                        Cookies sesyjne: są przechowywane na Urządzeniu
                        Użytkownika i pozostają tam do momentu zakończenia sesji
                        danej przeglądarki. Zapisane informacje są wówczas
                        trwale usuwane z pamięci Urządzenia. Mechanizm cookies
                        sesyjnych nie pozwala na pobieranie jakichkolwiek danych
                        osobowych ani żadnych informacji poufnych z Urządzenia
                        Użytkownika.
                      </li>
                      <li>Cookies trwałe: są przechowywane na Urządzeniu Użytkownika i pozostają tam do momentu ich
                        skasowania. Zakończenie sesji danej przeglądarki lub wyłączenie Urządzenia nie powoduje ich
                        usunięcia z Urządzenia Użytkownika. Mechanizm cookies trwałych nie pozwala na pobieranie
                        jakichkolwiek danych osobowych, ani żadnych informacji poufnych z Urządzenia Użytkownika.
                      </li>
                    </ol>
                  </li>
                  <li value="4">
                    Użytkownik ma możliwość ograniczenia lub wyłączenia dostępu plików cookies do swojego Urządzenia. W
                    przypadku skorzystania z tej opcji korzystanie ze Serwisu będzie możliwe, poza funkcjami, które ze
                    swojej natury wymagają plików cookies.
                  </li>
                </ol>
              </li>
              <li value="4">
                <strong>Cele w jakich wykorzystywane są Cookies</strong>
                <ol type="1" start="4">
                  <li value="4">
                    Administrator wykorzystuje Cookies Administratora w następujących celach:
                    <ol type="a" className="reset-list">
                      <li>Konfiguracji serwisu i realizacji procesów niezbędnych dla pełnej funkcjonalności stron
                        internetowych - rozpoznania urządzenia Użytkownika Serwisu i odpowiednio wyświetlenia strony
                        internetowej, dostosowanej do jego indywidualnych potrzeb; - zapamiętania danych potrzebnych do
                        korzystania z serwisu.
                      </li>
                      <li>Analiz i badań oraz audytu oglądalności - tworzenia anonimowych statystyk, które pomagają
                        zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych Serwisu, co
                        umożliwia ulepszanie ich struktury i zawartości.
                      </li>
                      <li>Zapewnienia bezpieczeństwa i niezawodności serwisu.</li>
                    </ol>
                  </li>
                  <li value="4">
                    Administrator usługi wykorzystuje Cookies Zewnętrzne w następujących celach:
                    <ol type="a" className="reset-list">
                      <li>prezentowania treści multimedialnych na stronach internetowych Serwisu, które są pobierane z
                        zewnętrznego serwisu internetowego;
                      </li>
                      <li>zbierania ogólnych i anonimowych danych statycznych za pośrednictwem narzędzi analitycznych
                        Google Analytics (administrator cookies zewnętrznego: Google Inc z siedzibą w USA);
                      </li>
                      <li>prezentowania reklam dostosowanych do preferencji Użytkownika z wykorzystaniem narzędzia
                        internetowej reklamy - w celu zbierania ogólnych i anonimowych danych do realizacji akcji
                        reklamowych za pośrednictwem list remarketingowych w systemie reklamowym Google AdWords
                        (administrator cookies zewnętrznego Google Inc - z siedzibą w USA). Użytkownicy odwiedzający
                        stronę główną i podstrony Serwisu - dodawani są w takim wypadku do list remarketingowych, które
                        pozwalają prezentować im treści reklamowe w sieci wyszukiwania i w sieci reklamowej Google.
                        Użytkownik jest aktywny na liście remarketingowej przez okres od 45 do 90 dni.;
                      </li>
                      <li>zalogowania do serwisu za pomocą serwisu skocznościowego;</li>
                      <li>wykorzystania funkcji interaktywnych w celu popularyzacji serwisu za pomocą serwisów
                        społecznościowych;
                      </li>
                      <li>prezentowania opinii na stronach internetowych Serwisu, które są pobierane z zewnętrznego
                        serwisu internetowego;
                      </li>
                      <li>wykorzystania funkcji w celu ułatwienia komunikacji za pośrednictwem strony internetowej
                        Serwisu, które są pobierane z zewnętrznego serwisu internetowego.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li value="4">
            <strong>
              Możliwości określenia warunków przechowywania lub uzyskiwania dostępu przez Cookies
            </strong>
            <ol type="1" start="4">
              <li value="4">Użytkownik może samodzielnie i w każdym czasie zmienić ustawienia dotyczące plików Cookies,
                określając warunki ich przechowywania i uzyskiwania dostępu przez pliki Cookies do Urządzenia
                Użytkownika. Zmiany ustawień, o których mowa w zdaniu poprzednim, Użytkownik może dokonać za pomocą
                ustawień przeglądarki internetowej lub za pomocą konfiguracji usługi. Ustawienia te mogą zostać
                zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach
                przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu Cookies na urządzeniu
                Użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w
                ustawieniach oprogramowania (przeglądarki internetowej).
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </StyledList>
    <p className="body-small">Poniżej przedstawiamy, jak można zmienić ustawienia przeglądarek internetowych w zakresie
      stosowania plików cookies:</p>
    <ol className="body-small" type="a">
      <li>Przeglądarka Internet Explorer</li>
      <li>Przeglądarka Mozilla FireFox</li>
      <li>Przeglądarka Chrome</li>
      <li>Przeglądarka Safari</li>
      <li>Przeglądarka Opera</li>
    </ol>
    <p className="body-small"><strong>4.3.2. Użytkownik może w każdej chwili usunąć pliki Cookies korzystając z
      dostępnych funkcji w przeglądarce internetowej, której używa.</strong></p>
    <p className="body-small"><strong>4.3.3. Ograniczenie stosowania plików Cookies może wpłynąć na niektóre
      funkcjonalności dostępne na stronie internetowej Serwisu.</strong></p>
  </div>
)

export default PrivacyPolicyPage
